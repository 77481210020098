var waitPanel = {
    divId: 'waitPanel',
    wait: function (content) {
        $('#waitPanelButtons').hide();
        var convertedContent = strBR(content);
        dynamic.showOverlay('waitPanelOverlay', 30);
        dynamic.setDivIdContent('waitPanelContent', convertedContent);
        var top = $(window).scrollTop() + 100;
        dynamic.centerDiv(this.divId);
        $('#' + this.divId).css({
            top: top + 'px'
        });
        $('#' + this.divId).fadeIn(function () {
            setTimeout(function () {
                waitPanel.displayDismiss();
            }, 13000);
        });
    },
    displayDismiss: function () {
        $('#waitPanelButtons').show();
        $(window).keyup(function (e) {
            tempThis.onkeyup(e);
        });
        var tempThis = this;
    },
    dismiss: function () {
        $('#waitPanelButtons').hide();
        $('#' + this.divId).fadeOut(null, function () {
            dynamic.setDivIdContent('waitPanelContent', '');
        });
        dynamic.hideOverlay('waitPanelOverlay');
        $(window).unbind('keyup');
    },
    onkeyup: function (event) {
        if (isEnterEvent(event)) {
            waitPanel.dismiss();
        }
    }
};
